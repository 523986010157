/* Copyright 2023 (Unpublished) Verto Inc. */

app-mah-verto-form,
edit-mode-renderer {
  @import '../../../node_modules/primeflex/primeflex.min';
  @import './variables.scss';

  input {
    width: 100%;
  }

  p-dropdown {
    width: 100%;
    > div {
      width: 100%;
    }
  }

  .p-grid {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .p-label {
    > label {
      display: block;
      font-weight: normal;
      color: $color-text-primary;
    }
  }

  .small-header {
    font-size: 12px;
    color: grey;
    margin-bottom: -7px;
    margin-left: 15px;
  }

  .item {
    &-dropzone {
      @include card-styled-no-shadow();
      min-height: 60px;
      padding: 10px;
      margin: 5px;

      .cdk-drop-list-dragging {
        background: lightgreen;
      }

      .cdk-drag-placeholder {
        opacity: 0.5;
      }
    }
  }

  .p-autocomplete-input-token input {
    width: 20em;
  }

  .p-autocomplete-input-token input:disabled {
    width: 20em;
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: transparent;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }
  }

  // ============ Special Classes ===============

  .no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .hidden {
    display: none !important;
  }

  .text-center {
    text-align: center !important;
  }

  .w-100 {
    width: 100%;
  }

  // ============= Mock Table ===============
  .has-borders {
    border: 1px solid rgb(221, 221, 221);
  }
}
