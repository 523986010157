/* Copyright 2023 (Unpublished) Verto Inc. */

@import '../../variables.scss';

.p-component:disabled {
  border: none;
  background-color: #ececec;
  opacity: 1;

  &::-webkit-input-placeholder {
    color: transparent;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
  }

  .p-dropdown-label {
    color: transparent;
    background-color: #ececec;
  }

  .p-dropdown-trigger-icon {
    color: transparent;
  }
}

body .flow-theme {
  .p-state-disabled,
  .p-component:disabled {
    border: none;
    background-color: #ececec;
    opacity: 1;

    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: transparent;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }

    .p-dropdown-label {
      background-color: #ececec;
    }

    .p-dropdown-trigger-icon {
      color: transparent;
    }
  }

  .p-label {
    > label {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: black;
    }
  }

  .p-accordion {
    .p-accordion-header {
      margin: 0;
      a {
        font-family: 'Poppins', sans-serif;
        background-color: #f5f5f5;
        border-color: #ddd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: black;
        font-weight: 400;
        font-size: 14px;
        outline: 0;

        .p-accordion-toggle-icon {
          color: rgb(51, 51, 51);
        }
      }

      &:not(.p-state-disabled).p-state-active a {
        background-color: #f5f5f5;
        border-color: #ddd;
        color: black;

        .p-accordion-toggle-icon {
          color: rgb(51, 51, 51);
        }
      }
    }

    .p-accordion-content {
      border-top: none;
      border-color: #ddd;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
