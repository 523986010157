/* Copyright 2023 (Unpublished) Verto Inc. */

/* You can add global styles to this file, and also import other style files */

// TODO(tiagovalverde): experimental, remove if not used
// default theme
:root {
  --primary-color: #42b149;
  --primary-color-light: #ffffff;
  --secondary-color: #152046;
  --tertiary-color: #ffcb00;
  --navbar-border-color: #62af56;
}

.auto-grid {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
}
