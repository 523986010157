/* Copyright 2023 (Unpublished) Verto Inc. */

@import '../variables';

@mixin desktop {
  @media (max-width: #{$large-device-width}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$medium-device-width}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$small-device-width}) {
    @content;
  }
}
@mixin extra-small-mobile {
  @media (max-width: #{$extra-small-device-width}) {
    @content;
  }
}
@mixin extra-extra-small-mobile {
  @media (max-width: #{$extra-extra-small-device-width}) {
    @content;
  }
}

@mixin desktop-height {
  @media (max-height: #{$large-device-width}) {
    @content;
  }
}
@mixin tablet-height {
  @media (max-height: #{$medium-device-width}) {
    @content;
  }
}
@mixin mobile-height {
  @media (max-height: #{$small-device-width}) {
    @content;
  }
}
@mixin extra-small-mobile-height {
  @media (max-height: #{$extra-small-device-width}) {
    @content;
  }
}
@mixin extra-extra-small-mobile-height {
  @media (max-height: #{$extra-extra-small-device-width}) {
    @content;
  }
}
