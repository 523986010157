/* Copyright 2023 (Unpublished) Verto Inc. */

/* You can add global styles to this file, and also import other style files */
.p-datatable {
  table {
    border-radius: 10px 10px 0px 0px;
    border-style: hidden;
    box-shadow: none;
  }

  .p-datatable-header {
    background-color: white;
    border: 0px;

    .results-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 10px;

      .filters {
        display: flex;
        flex-direction: row;
        .filter {
          flex: 1;
          width: 300px;
          margin-right: 15px;
          height: 33px;
        }
      }
    }
  }

  .p-datatable-wrapper {
    padding: 1px;
    .p-datatable-thead tr {
      th {
        background-color: #f0f1f2;
        border: none;
        color: black;
        font-weight: 500;
        text-align: left;
        padding: 10px;
        &:first-child {
          margin: auto auto;
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
      .p-sortable-column {
        .p-sortable-column-icon {
          color: #778699;
          font-size: 12px;
        }
      }
    }
    .p-datatable-tbody {
      > tr {
        min-height: 47px;
        &.p-highlight {
          color: #333333;
        }
        &:nth-child(odd) {
          background-color: #f9fafd;
        }
        &:nth-child(even) {
          background-color: #ffffff;
        }
        > td {
          padding: 10px 12px;
          font-size: 14px;
          border: none;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          max-width: 300px;
          &:hover {
            text-overflow: clip;
            white-space: normal;
            word-break: break-all;
          }
          &.p-cell-editing {
            button.p-button-icon-only {
              width: 2.357em;
            }
          }

          .pdf-certificate {
            display: flex;
            justify-content: center;

            .icon-button {
              color: #023af9;
              background: none;
              border: none;
              font-size: 22px;

              &:disabled {
                color: #a0a0a0;
              }
            }
          }
        }
      }
    }
  }

  .p-paginator {
    background: white;
    border: 0px;
    margin-top: 20px;
  }
}

.list-input,
.p-treeselect.p-component {
  border-radius: 8px;
  background: #f4f4f4;
  border: none;
  width: 100%;
  padding: 0.55em 1.1em;
  text-transform: uppercase;
  color: #000;
  font-weight: 500;
  .p-treeselect-label,
  .p-multiselect-label.p-placeholder,
  .p-multiselect-label {
    padding: 0;
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
  }
}

.list-input::placeholder {
  text-transform: uppercase;
  color: #000;
  font-weight: 500;
}

.list-input:hover,
.list-input.p-button:enabled:hover {
  background: #e1dfdfa7;
  color: #000;
}

p-calendar {
  width: 100%;
  span {
    width: 100%;
  }
}

.p-paginator {
  background: white;
  border: 0;
  gap: 0.3em;

  .p-dropdown {
    background: #f4f4f4;
    border: 0;
    border-radius: 8px;
  }

  .p-paginator-first {
    order: 1;
  }

  .p-paginator-prev {
    order: 2;
  }

  .p-paginator-current {
    order: 3;
    color: #000;
  }

  .p-paginator-next {
    order: 4;
  }

  .p-paginator-last {
    order: 5;
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    border-radius: 8px;
    background: #f4f4f4;
  }
}

.filter-panel {
  width: 17em;
}

/* This might not be required. The styling from open-clinic was getting applied for some reason*/
:not(span.pi):not(i.pi):not(a.p-toast-close-icon.pi):not(span.pi-clock):not(button):not(
    .header-label
  ):not(a.button)
  ul:not(.p-dropdown-items, .p-autocomplete-items) {
  margin-left: 0;
}

.upload-modal {
  border-radius: 9px;
}

/* for Windows, option color defaults to white? */
option,
option:checked {
  color: black;
}

.filter-listbox {
  border: 0;
  .p-listbox-list .p-listbox-item.p-highlight {
    background: #ebebeb;
    color: black;
  }
}
