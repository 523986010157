/* Copyright 2023 (Unpublished) Verto Inc. */

@use '@angular/material' as mat;

@import './node_modules/bootstrap/scss/bootstrap';
@import '../style/mixins/responsive';

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  background: #f5f6f8; /* */
}

body {
  min-height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  .open-clinic {
    .p-calendar {
      width: 100%;
    }
    .p-datepicker {
      border: none !important;
    }
    .p-datepicker-title {
      color: #3f81ff;
      font-weight: 600;
    }

    .p-datepicker {
      table {
        td {
          &.p-datepicker-today {
            > a {
              border-radius: 50%;
              background-color: #ffffff;

              &.p-state-active {
                background-color: #e8f0ff;
                color: #2a73ff;
              }

              &:hover:not(.p-state-active) {
                background-color: #eaeaea;
              }
            }
          }

          > a {
            border-radius: 50%;

            &.p-state-active {
              background-color: #e8f0ff;
              color: #2a73ff;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

    ul.innerHTML {
      margin-left: 1em;
    }

    /* bootstrap override */
    .row.row-override {
      margin-left: 0;
      margin-right: 0;

      .col-md-4,
      .col-md-6,
      .col-md-12 {
        padding-left: 0;
        padding-right: 0;
      }

      .col-md-6 {
        &:nth-child(even) {
          box-sizing: border-box;
          padding-left: 5px;

          @include mobile {
            padding-left: 0;
          }
        }
      }

      .col-md-4 {
        &:not(:first-child):not(:nth-child(4)) {
          box-sizing: border-box;
          padding-left: 5px;

          @include mobile {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@for $i from 1 through 100 {
  @if $i % 10 == 0 {
    .w-#{$i} {
      width: $i * 1%;
    }

    .h-#{$i} {
      height: $i * 1%;
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      display: flex;
      height: 46px;
      align-items: center;
    }
  }
}

body {
  .patient-experience-button {
    background-color: #048037;
    color: #ffffff;

    &.secondary {
      background-color: #31363f;
    }

    &:disabled {
      color: #6a6b6c;
      background-color: #31363f;
      color: #ffffff;
    }

    &.mat-button {
      &:disabled {
        .mat-button-wrapper {
          color: #6a6b6c;
        }
      }
    }
  }
}

.kit-header {
  display: block;
  place-items: center;
  width: 100%;
  text-align: center;
}
.kit-header-icon {
  position: absolute;

  @include mobile {
    display: none;
  }
}

@include mat.core();

$shell-primary: mat.define-palette(mat.$indigo-palette);
$shell-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$shell-warn: mat.define-palette(mat.$red-palette);

$shell-theme: mat.define-light-theme(
  (
    color: (
      primary: $shell-primary,
      accent: $shell-accent,
      warn: $shell-warn,
    ),
  )
);

@include mat.all-component-themes($shell-theme);

body {
  @include mobile {
    overscroll-behavior: none;
  }
}
