/* Copyright 2023 (Unpublished) Verto Inc. */

$color-text-primary: #42505c;
$color-text-placeholder: #b4b9be;
$color-flow-blue: #3c75d9;
$color-border: #eff0f2;
$color-overall-background: #f5f7fa;
$color-overall-background-transparent: rgba(245, 247, 250, 0);
$color-main-background: white;
$color-clear: rgba(255, 255, 255, 0);

@mixin card-styled {
  display: block;
  border: 1.5px solid $color-border;
  background-color: $color-main-background;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  margin: 0;
  padding: 10px;
}

@mixin card-styled-no-shadow {
  @include card-styled();
  box-shadow: none;
}
