/* Copyright 2023 (Unpublished) Verto Inc. */

:root {
  --ywho-main-color: #152046;
}

/* You can add global styles to this file, and also import other style files */
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.section-underline {
  border-top: solid 2.75px var(--ywho-main-color);
}

/**
 * Common styles for defining a DFD page layout.
 */
.page-container {
  background-color: #dbf3f3;
  min-height: 100vh;

  .section-divider {
    width: 80vw;
    height: 2px;
    border: 1px solid #152046;
    border-radius: 4px;
    margin: auto;

    &-text {
      position: relative;
      top: -40px;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .search-container {
    margin-top: 20px;

    .input-container {
      justify-content: center;
      margin: auto;
      width: min(740px, 90vw);
    }

    .results-text {
      text-align: center;
      margin: 20px 0;

      .results-count {
        font-weight: 600;
      }
    }

    .results-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: min(740px, 90vw);
      margin: auto;

      > * {
        width: 100%;
      }
    }
  }
}

.loading-container {
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20vh;
  height: 51vh;

  i {
    font-size: 10rem;
  }
}
