/* Copyright 2023 (Unpublished) Verto Inc. */

@import '../../variables.scss';

body .drop-shadow-theme {
  *:not(.pi) {
    font-family: 'Poppins', sans-serif;
  }

  .p-label {
    > label {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: $color-text-primary;
    }
  }

  .p-accordion {
    .p-accordion-header {
      margin: 0;
      a {
        background-color: $color-clear;
        border-color: $color-clear;
        color: $color-text-primary;

        outline: none;
        text-decoration: none;

        .p-accordion-toggle-icon {
          color: $color-text-primary;
          margin-left: -0.5em;
        }

        .p-accordion-header-text {
          font-weight: bold;
        }
      }

      &:not(.p-state-disabled).p-state-active a {
        background-color: $color-clear;
        border-color: $color-clear;
        color: $color-text-primary;

        .p-accordion-toggle-icon {
          color: $color-text-primary;
        }
      }
    }

    .p-accordion-content {
      @include card-styled();
    }
  }
}

#toolbar .p-accordion .p-accordion-content {
  padding: 0;
}
