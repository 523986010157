/* Copyright 2023 (Unpublished) Verto Inc. */

/* You can add global styles to this file, and also import other style files */
.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      font-size: 18px;
      font-family: Poppins, Arial, Helvetica, sans-serif;
    }
  }
}

body {
  font-size: 18px;
}

*
  :not(span.pi):not(i.pi):not(a.p-toast-close-icon.pi):not(span.pi-clock):not(button):not(
    .header-label
  ):not(a.button) {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  ol,
  ul:not(.p-dropdown-items, .p-autocomplete-items) {
    list-style: disc;
    margin-left: 2rem;

    li {
      margin: 0.5rem;
    }
  }

  //.row {
  //  // To override Bootstrap .row margins of -15px.
  //  margin-left: 0 !important;
  //  margin-right: 0 !important;
  //}
}

body .p-datepicker-inline {
  background-color: rgba(0, 0, 0, 0);

  @media screen and (max-width: 769px) {
    background-color: white;
  }
}

.p-datepicker-month,
.p-datepicker-year {
  font-size: 22px !important;
}

body .p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker-next {
  &:hover {
    background-color: #f6f8fc;
    color: #0139f9;
  }

  &:active {
    background-color: #e8f0ff;
    color: #0139f9;
  }
}

.debug {
  padding: 1rem;

  details {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 0.5rem 0.5rem 0;
    margin-bottom: 0.5rem;
  }

  summary {
    font-weight: bold;
    margin: -0.5rem -0.5rem 0;
    padding: 0.5rem;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  details[open] {
    padding: 0.5rem;
  }

  details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: 0.5rem;
  }

  pre {
    font-size: 12px !important;
  }
}
