/* Copyright 2023 (Unpublished) Verto Inc. */

/* You can add global styles to this file, and also import other style files */

.input-wrapper {
  display: flex;
  flex-direction: column;
}

.input {
  padding: 0.5rem 0.6rem;
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  background-color: #fff;
}

.input-label {
  color: #5b5c5c;
  font-size: 0.8rem;
}
